import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ITicketRelease, ISelectedAddon } from "../../../../types";
import { RootState } from "../../../../store";
import { Trans, useTranslation } from "react-i18next";
import usePalette from "../../../../theme/use_palette";
import { closeCartModal } from "../../../../redux/features/ticketOrderSlice";
import InformationModal from "../../../modal/information";
import { Box, Button, Divider, Link, Stack, useTheme } from "@mui/joy";
import StyledText from "../../../text/styled_text";
import { LEGAL_LINKS } from "../../../legal/legal_links";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import StyledButton from "../../../buttons/styled_button";
import { requiresUpfrontPayment } from "../../../../utils/manage_event/can_edit_payment_deadline";
import { useMediaQuery } from "@mui/material";

interface CartSummaryModalProps {
  ticketRelease: ITicketRelease;
  onProceed: (
    ticketsData: {
      ticket_type_id: number;
      addons: ISelectedAddon[];
    }[]
  ) => void;
}

const CartSummaryModal: React.FC<CartSummaryModalProps> = ({
  ticketRelease,
  onProceed,
}) => {
  const dispatch = useDispatch();
  const { isCartModalOpen, items } = useSelector(
    (state: RootState) => state.ticketOrder
  );
  const { t } = useTranslation();
  const palette = usePalette();
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  // Filter items for the current ticket release
  const cartItemsForRelease = items.filter(
    (item) => item.ticket.ticket_release_id === ticketRelease.id
  );

  const handleClose = () => {
    dispatch(closeCartModal());
  };

  // Calculate total amount including addons
  const totalAmount = cartItemsForRelease.reduce((total, item) => {
    const ticketPrice = item.ticket.price;
    const addonsPrice = item.addons.reduce((sum, addon) => {
      const addonInfo = ticketRelease.add_ons?.find((a) => a.id === addon.id);
      return sum + (addonInfo ? addonInfo.price * addon.quantity : 0);
    }, 0);
    return total + ticketPrice + addonsPrice;
  }, 0);

  const handleProceed = () => {
    // Prepare tickets data for submission
    const ticketsData = cartItemsForRelease.map((item) => ({
      ticket_type_id: item.ticket.id,
      addons: item.addons,
    }));

    // Close the cart modal
    dispatch(closeCartModal());

    // Open the MakeTicketOrderWorkflow modal via the passed prop
    onProceed(ticketsData);
  };

  return (
    <InformationModal
      isOpen={isCartModalOpen}
      onClose={handleClose}
      title={t("event.ticket_release.checkout.cart_summary")}
      width="750px"
    >
      <Box>
        <StyledText
          level="body-md"
          color={palette.charcoal}
          sx={{
            mb: 2,
          }}
        >
          {t("event.ticket_release.checkout.cart_description")}
        </StyledText>
        {/* Display cart items and total */}
        {cartItemsForRelease.map((item, index) => (
          <Box key={index} mb={2}>
            <Box display="flex" justifyContent="space-between">
              <Stack direction="row" spacing={1}>
                <ConfirmationNumberIcon />
                <StyledText level="body-md" color={palette.charcoal}>
                  {item.ticket.name}
                </StyledText>
              </Stack>
              <StyledText level="body-sm" color={palette.charcoal}>
                {item.ticket.price.toFixed(2)} kr
              </StyledText>
            </Box>
            {/* Display addons for this ticket */}
            {item.addons.length > 0 && (
              <Box ml={2} mt={1}>
                {item.addons.map((addon) => {
                  const addonInfo = ticketRelease.add_ons?.find(
                    (a) => a.id === addon.id
                  );
                  return (
                    <Box
                      key={addon.id}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Stack direction="row" spacing={1}>
                        <AddCircleOutlineIcon />
                        <StyledText level="body-sm" color={palette.charcoal}>
                          {addon.quantity} x {addonInfo?.name}
                        </StyledText>
                      </Stack>
                      <StyledText level="body-sm" color={palette.charcoal}>
                        {((addonInfo?.price || 0) * addon.quantity).toFixed(2)}{" "}
                        kr
                      </StyledText>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        ))}
        <Divider sx={{ mt: 0.5, mb: 1 }} />
        <StyledText
          level="body-md"
          fontWeight={700}
          color={palette.charcoal}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {t("Total")}: {totalAmount.toFixed(2)} kr
        </StyledText>

        {/* Display relevant texts */}
        <Box mt={3}>
          <StyledText
            level="body-sm"
            fontWeight={400}
            fontSize={14}
            color={palette.black}
            sx={{ textWrap: "balance" }}
          >
            <Trans i18nKey="event.ticket_release.information_processing_policy_info">
              By requesting a ticket you agree to share your food preferences
              and user details with the event organizer until the event is over.
              Information collected will be processed in accordance with the
              Chapter's information processing policy,
              <Link href={LEGAL_LINKS.privacy_policy} target="_blank">
                PRIVACY POLICY
              </Link>
              <Link href={LEGAL_LINKS.refund_policy} target="_blank">
                REFUND POLICY
              </Link>
              <Link href={LEGAL_LINKS.terms_and_conditions} target="_blank">
                TERMS AND CONDITIONS
              </Link>
              .
            </Trans>
          </StyledText>
        </Box>

        {/* Proceed Button */}
        <StyledButton
          size="md"
          onClick={handleProceed}
          sx={{ mt: 4 }}
          color={palette.alwaysWhite}
          bgColor={palette.primaryMain}
        >
          {requiresUpfrontPayment(
            ticketRelease.ticket_release_method_detail.ticket_release_method
          )
            ? t("event.ticket_release.checkout.proceed_payment")
            : t("event.ticket_release.checkout.proceed_request")}
        </StyledButton>
      </Box>
    </InformationModal>
  );
};

export default CartSummaryModal;
