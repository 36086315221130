import React, { useState } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  AccordionGroup,
  accordionSummaryClasses,
  accordionClasses,
} from "@mui/joy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ITicket, IEventFormField } from "../../../types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import StyledButton from "../../buttons/styled_button";
import StyledText from "../../text/styled_text";
import usePalette from "../../../theme/use_palette";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { submitFormFieldResponseRequest } from "../../../redux/features/formFieldsSlice";
import { RootState } from "../../../store";
import FormSelect, { ISelectOption } from "../../forms/form_select";
import FormMultiSelect from "../../forms/form_multi_select";
import {
  StyledFormLabel,
  StyledFormLabelWithHelperText,
} from "../../forms/form_labels";
import { FormCheckbox, FormInput, FormTextarea } from "../../forms/input_types";
import { StyledErrorMessage } from "../../forms/messages";
import { lightenColor } from "../../../utils/manager/color";
import { selectAccentColor } from "../../../redux/features/managerThemeSlice";

interface EditFormFieldResponseProps {
  formFields: IEventFormField[];
  tickets: ITicket[];
  isGuestCustomer?: boolean;
  eventID: number;
}

const createField = (field: IEventFormField) => {
  const transformOptions = (options: string[]): ISelectOption[] => {
    return options.map((option, index) => ({
      id: index,
      value: option,
      label: option,
    }));
  };

  const getFieldType = (type: string, field: IEventFormField) => {
    switch (type) {
      case "text":
        return (
          <FormTextarea
            name={field.id.toString()}
            label="text"
            placeholder="Enter text"
            required={field.is_required}
            overrideStyle={{ width: "90%" }}
          />
        );
      case "number":
        return (
          <FormInput
            name={field.id.toString()}
            label="number"
            type="number"
            placeholder="Enter number"
            required={field.is_required}
          />
        );
      case "checkbox":
        return <FormCheckbox name={field.id.toString()} label="checkbox" />;
      case "select":
        return (
          <FormSelect
            name={field.id.toString()}
            options={transformOptions(field.options || [])}
            label="select"
            required={field.is_required}
          />
        );
      case "multiselect":
        return (
          <FormMultiSelect
            name={field.id.toString()}
            label="multiselect"
            options={transformOptions(field.options || [])}
            required={field.is_required}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      key={field.id}
      sx={{
        my: 0.5,
      }}
    >
      <StyledFormLabel fontSize={14}>{field.name}</StyledFormLabel>
      {getFieldType(field.type, field)}
      <StyledFormLabelWithHelperText fontSize={12}>
        {field.description}
      </StyledFormLabelWithHelperText>
      <StyledErrorMessage name={field.id.toString()} />
    </Box>
  );
};

const EditFormFieldResponse: React.FC<EditFormFieldResponseProps> = ({
  formFields,
  tickets,
  eventID,
  isGuestCustomer = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { guestCustomer } = useSelector(
    (state: RootState) => state.guestCustomer
  );

  const accentColor = useSelector(selectAccentColor);
  const palette = usePalette();

  const [expanded, setExpanded] = useState<string | false>(
    tickets[0]?.id.toString() || false
  );

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleSubmit = (values: any, ticketId: number) => {
    const formFieldValues = formFields.map((field) => ({
      event_form_field_id: field.id,
      value: Array.isArray(values[field.id.toString()])
        ? values[field.id.toString()]
        : values[field.id.toString()].toString(),
    }));

    dispatch(
      submitFormFieldResponseRequest({
        formFieldValues,
        event_id: eventID,
        ticketId: ticketId,
        isGuestCustomer,
        guestCustomer: isGuestCustomer ? guestCustomer! : undefined,
      })
    );
  };

  const createValidationSchema = () => {
    let schema: any = {};
    formFields.forEach((field) => {
      switch (field.type) {
        case "text":
          schema[field.id.toString()] = field.is_required
            ? Yup.string().required(`${field.name} is required`)
            : Yup.string();
          break;
        case "number":
          schema[field.id.toString()] = field.is_required
            ? Yup.number().required(`${field.name} is required`)
            : Yup.number();
          break;
        case "checkbox":
          schema[field.id.toString()] = field.is_required
            ? Yup.boolean().oneOf([true], `${field.name} must be checked`)
            : Yup.boolean();
          break;
        case "select":
          schema[field.id.toString()] = field.is_required
            ? Yup.string().required(`${field.name} is required`)
            : Yup.string().nullable();
          break;
        case "multiselect":
          schema[field.id.toString()] = field.is_required
            ? Yup.array().min(1, `${field.name} is required`)
            : Yup.array().nullable();
          break;
        default:
          break;
      }
    });
    return Yup.object().shape(schema);
  };

  const createInitialValues = (ticket: ITicket) => {
    const values = ticket.event_form_responses || [];
    let initialValues: any = {};
    formFields.forEach((field) => {
      const response = values.find(
        (value) => value.event_form_field_id === field.id
      );
      initialValues[field.id.toString()] = response
        ? response.value
        : field.type === "checkbox"
        ? false
        : field.type === "multiselect"
        ? []
        : "";
    });
    return initialValues;
  };

  return (
    <Box>
      <StyledText level="h3" color={palette.primaryDark} fontSize={22}>
        {t("event_form_fields.title")}
      </StyledText>
      <StyledText level="body-sm" fontSize={16} color={palette.charcoal}>
        {t("event_form_fields.description")}
      </StyledText>

      <AccordionGroup
        disableDivider
        transition={{
          initial: "0.3s ease-out",
          expanded: "0.2s ease",
        }}
        sx={{
          mt: 1,
          [`& .${accordionSummaryClasses.indicator}`]: {
            transition: "0.2s",
          },
          [`& .custom-accordion [aria-expanded="true"] .${accordionSummaryClasses.indicator}`]:
            {
              transform: "rotate(45deg)",
            },
          [`& .${accordionClasses.root}`]: {
            maxWidth: 500,
            marginTop: "0.5rem",
            transition: "0.2s ease",
            '& button:not([aria-expanded="true"])': {
              transition: "0.2s ease",
              paddingBottom: "0.625rem",
            },
          },
          [`& .${accordionClasses.root}.${accordionClasses.expanded}`]: {
            bgcolor: palette.offWhite,
            borderRadius: "md",
            borderBottom: "1px solid",
            borderColor: palette.accentMain,
            boxShadow: `0 2px 5px ${palette.charcoal}`,
          },
          '& .custom-accordion [aria-expanded="true"]': {
            boxShadow: `inset 0 -1px 0 ${palette.charcoal}`,
          },
          [`& .custom-accordion [aria-expanded="false"]`]: {
            border: `1px solid ${palette.charcoal}`,
            borderRadius: "md",
            backgroundColor: palette.white,
            color: palette.white,
            transition: "background-color 0.2s ease",
            "&:hover": {
              backgroundColor: palette.greyLight,
            },
          },
        }}
      >
        {tickets.map((ticket, index) => {
          const isDeleted = !!ticket.deleted_at;
          return (
            <Accordion
              key={ticket.id}
              disabled={isDeleted}
              expanded={expanded === ticket.id.toString()}
              onChange={handleChange(ticket.id.toString())}
              className="custom-accordion"
            >
              <AccordionSummary
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <StyledText
                  level="body-sm"
                  fontSize={16}
                  fontWeight={600}
                  color={palette.primaryDark}
                >
                  {t("event_form_fields.ticket_number", { number: index + 1 })}
                </StyledText>
              </AccordionSummary>
              <AccordionDetails>
                <Formik
                  initialValues={createInitialValues(ticket)}
                  validationSchema={createValidationSchema()}
                  validateOnMount={true}
                  validateOnChange={true}
                  validateOnBlur={true}
                  onSubmit={(values) => handleSubmit(values, ticket.id)}
                >
                  {({ isValid }) => (
                    <Form>
                      <StyledText
                        level="body-sm"
                        fontSize={16}
                        color={palette.charcoal}
                      >
                        {t("event_form_fields.ticket_number_description", {
                          number: index + 1,
                        })}
                      </StyledText>
                      {formFields.map((field) => createField(field))}
                      <StyledButton
                        type="submit"
                        size="md"
                        bgColor={palette.successLight}
                        color={palette.alwaysBlack}
                        disabled={!isValid}
                        sx={{ mt: 2 }}
                      >
                        {t("form.button_save")}
                      </StyledButton>
                    </Form>
                  )}
                </Formik>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </AccordionGroup>
    </Box>
  );
};

export default React.memo(EditFormFieldResponse);
